.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 84px;
  max-width: 256px;
  height: 100vh;
  padding: 32px 30px 22px;
  background: #ffffff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: width 0.3s ease;
}

.sidebar-container-open {
  width: 100%;
  transition: width 0.3s ease-in;
}

.nav-link span:first-child {
  width: 44px;
}

.nav-link span:last-child {
  width: 0;
  white-space: nowrap;
  transition: width 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-container-open .nav-link span:last-child {
  width: 100%;
  transition: width 0.3s ease;
}

/* Sidebar links */
.nav-link {
  display: flex !important;
  padding: min(1.2vh, 12px) 0 !important;
  align-items: center;
}

.sidebar-content .nav-link:first-child {
  padding: 0 0 min(1.2vh, 12px) 0 !important;
}

.sidebar-navigation-footer .nav-link:last-child {
  padding: min(1.2vh, 12px) 0 0 0 !important;
}

.nav-link:hover,
.nav-link:focus-visible {
  outline: none;
  color: var(--inchcape-blue);
  filter: brightness(0) saturate(100%) invert(50%) sepia(89%) saturate(3000%)
    hue-rotate(160deg) brightness(93%) contrast(150%);
}

.nav-link.active {
  color: var(--inchcape-blue);
  filter: brightness(0) saturate(100%) invert(50%) sepia(89%) saturate(3000%)
    hue-rotate(160deg) brightness(93%) contrast(150%);
}

/* Styles for Inchcape Logo & Title */
.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-header div {
  margin-bottom: 12px;
}

.sidebar-header span {
  margin-bottom: 22px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  transition: width 0.3s ease;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-container-open .sidebar-header span {
  width: 100%;
  transition: width 0.3s ease;
}

/* Sidebar Logo Brands*/
.sidebar-logo {
  display: grid;
  justify-content: center;
  height: 81px;
}

.sidebar-logo div {
  /* width: 0; */
  transition: opacity 0.3s ease;
  white-space: nowrap;
  opacity: 0;
  max-height: 100%;
  /* overflow: hidden; */
}

.sidebar-container-open .sidebar-logo div {
  /* width: 100%; */
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Sidebar User Content */
.user-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: width 0.3s ease;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-container-open .user-items {
  width: auto;
  transition: width 0.3s ease;
}

.sidebar-user-content {
  padding: 34px 0 44px;
  gap: 18px;
}

.profile-name {
  display: inline-block;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: var(--inchcape-dark);
  width: 156px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile-email {
  width: 156px;
  display: inline-block;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.5);
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
}

.profile-role {
  display: inline-block;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--inchcape-blue);
}

/* Sidebar Content */
.content-sides {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 43vh;
}

.sidebar-navigation span,
a.nav-link span {
  margin: 0;
  color: var(--inchcape-grey);
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

/* Sidebar Footer */
.sidebar-navigation-footer span {
  margin: 0;
  color: rgba(0, 35, 58, 0.5) !important;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 11px !important;
  line-height: 14px;
}
