.dashboard-container {
  width: 100%;
  height: auto;
  grid-area: 1 / 1 / -1 / -1;
}

.dashboard-contents,
.campaign-performance {
  grid-gap: 40px;
}

.dashboard-header-title {
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--inchcape-dark-blue);
}

.campaign-wrap {
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: 344px;
}

.performance-wrap {
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  /* height: 438px; */
}

.task-wrap {
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.dashboard-header {
  border-bottom: 3px solid #ebeff2;
  padding: 16px 65px;
}

/* Dashboard dropdown styling */
.dashboard-dropdown p {
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #6a707e;
  margin: 0;
}

.dashboard-dropdown .k-picker-md .k-input-inner,
.dashboard-dropdown .k-picker-md .k-input-button {
  padding: 4px 0 4px 8px;
}

.dashboard-dropdown .k-i-caret-alt-down {
  color: #6a707e;
}

.dashboard-dropdown .k-picker-solid {
  border: none;
  width: 125px;
}

.dashboard-dropdown .k-input-value-text {
  font-family: "Poppins Bold" !important;
  color: var(--inchcape-blue);
}

.dashboard-contents .k-list-item-text {
  font-family: "Poppins Bold" !important;
}

.dropdown-list-items {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
  background: #fff;
}

.k-popup:has(.dropdown-list-items) {
  border-color: #fff;
}

.k-list-item.k-selected:has(.dropdown-list-items),
.k-list-item:hover:has(.dropdown-list-items),
.k-list-optionlabel:hover:has(.dropdown-list-items),
.k-hover.k-list-optionlabel:has(.dropdown-list-items),
.k-list-item.k-hover:has(.dropdown-list-items),
.k-list-item.k-selected:hover:has(.dropdown-list-items) {
  background: #fff;
  color: var(--inchcape-blue);
  font-family: "Poppins Bold";
}

/*Dashboard Campaign Grid Styling */
.dashboard-campaign .k-cell-inner > .k-link,
.dashboard-tasks .k-cell-inner > .k-link {
  justify-content: center;
  padding: 15px 12px;
  cursor: default;
}

.dashboard-campaign .k-table-md .k-table-td {
  padding: 12px 8px;
}

.dashboard-campaign .k-grid-container {
  height: 220px;
}

.dashboard-campaign .k-table-thead,
.dashboard-taks .k-table-thead {
  background-color: #fff;
}

.dashboard-campaign td.k-table-td,
.dashboard-tasks td.k-table-td {
  text-align: center;
}

.dashboard-campaign .k-grid tr {
  cursor: pointer;
}

.dashboard-campaign .k-grid-norecords {
  cursor: default !important;
}

/*Dashboard Tasks Grid Styling */
.dashboard-tasks .k-sort-icon {
  margin-left: 6px;
  cursor: pointer;
}

.dashboard-tasks .k-sort-icon span {
  color: rgba(0, 35, 58, 0.5);
}

.dashboard-tasks th.k-sorted,
.dashboard-tasks .k-grid tr {
  background-color: #fff;
  cursor: pointer;
}

.dashboard-tasks .k-grid-container {
  height: 656px;
}

.dashboard-tasks .k-pager,
.dashboard-tasks .k-pager-sizes {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-tasks .k-pager {
  flex-wrap: wrap;
  flex: 0.85 1;
}

.dashboard-tasks .k-grid-header .k-header {
  vertical-align: middle;
}

.dashboard-tasks .k-grid td {
  text-align: center;
}

.dashboard-tasks .k-input-values {
  width: 70px !important;
}

.table-header-cell {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
}

.table-header-cell .k-multiselect {
  position: relative;
  justify-content: center;
}

.table-header-cell .k-multiselect .k-input-values {
  width: 65px;
}

.table-header-cell .k-multiselect::after {
  content: url(../../assets/icons/filter-grey.svg);
  cursor: pointer;
  margin-top: 4px;
  display: inline-block;
  z-index: 2;
}

.table-header-cell .k-clear-value {
  display: none;
}

.table-header-cell .k-input-solid {
  border: none;
}

.table-header-cell .k-input-solid:focus-within,
.table-header-cell .k-input-solid:focus,
.table-header-cell .k-input-solid.k-focus {
  box-shadow: none;
}

/* .table-header-cell .k-input-md .k-input-values > .k-input-inner {
  display: none;
} */

.table-header-cell .k-multiselect .k-input-values {
  /* cursor: pointer; */
  flex-wrap: nowrap;
}

.table-header-cell .k-multiselect .k-input-inner {
  caret-color: transparent;
}

.table-header-cell .k-input-md .k-input-inner {
  /* cursor: pointer; */
  padding: 0;
}

.table-header-cell .k-chip-label {
  font-family: "Poppins Bold";
  color: rgba(0, 35, 58, 0.6);
}

.table-header-cell .k-chip:hover {
  background-color: #fff;
  cursor: default;
}

.table-header-cell .k-chip-actions {
  display: none;
}

/* UTIL STATUS TEXT */
.dashboard-tasks .status-holder {
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  /* padding: 5px 9px; */
}

/* UTIL STATUS BOX */
.status-common {
  text-align: center;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 4px 8px;
  border-radius: 2px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 5px rgba(0, 0, 0, 0.04); */
}

/* Dashboard Performance Grid Styling*/
.dashboard-performance-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 25px;
}

.dashboard-performance-container .k-chart {
  width: 360px;
  height: 275px;
}

.performance-status-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 275px;
  width: 200px;
}

@media screen and (max-width: 768px) {
  .dashboard-performance-container {
    flex-direction: column-reverse;
  }

  .performance-status-holder {
    align-items: center;
  }
}
