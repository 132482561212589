.campaigns {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.campaigns .header {
  padding: 1.5rem;
}

.campaigns .header.grid-view,
.campaigns .header.grid-view input {
  background-color: #fafafa;
}

.campaigns .header.alt-view,
.campaigns .header.alt-view input {
  background-color: var(--inchcape-white);
}

.campaigns .k-grid {
  height: 60vh;
}

.campaigns .k-grid tr {
  cursor: pointer;
}

.campaigns .grid-view .k-grid {
  border: 1px solid #fafafa;
}

.campaigns .alt-view .k-grid-header {
  display: none;
}

.campaigns .grid-cont {
  overflow: hidden;
  overflow-x: auto;
}

.campaigns .search img {
  width: 14px;
  height: 14px;
}

.campaigns .header .search button {
  padding: 11px;
}

.campaigns .header button {
  padding: 11px 32px;
  font-family: "Poppins Bold";
  font-size: 13px;
}

.campaigns .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.campaigns .offset-1 {
  margin-left: 6%;
}

.campaigns .custom-pager {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.campaigns .custom-pager > .imgs {
  flex: 0.25;
  display: flex;
  justify-content: space-evenly;
}

.campaigns .custom-pager > div:nth-child(2) {
  flex: 0.7;
}

.campaigns .k-table-row .k-button {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.campaigns th:first-of-type .k-cell-inner {
  justify-content: center;
}

.campaigns td:first-of-type img,
.campaigns .imgs img {
  cursor: pointer;
}

.campaigns .imgs img {
  padding: 10px;
  border-radius: 4px;
}

.campaigns .k-pager-info {
  display: block;
}

.campaigns .k-grid-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.campaigns span.selected {
  background: var(--inchcape-blue);
  border-radius: 4px;
}

.campaigns img.selected {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%)
    hue-rotate(327deg) brightness(106%) contrast(101%);
}

.campaigns .k-table {
  width: 100% !important;
}

.campaigns .campaign-name-normal {
  font-family: "Poppins";
  color: rgba(0, 35, 58, 0.9);
}

.campaigns .campaign-name-bold {
  font-family: "Poppins";
  font-weight: 600;
  color: rgba(0, 35, 58, 0.9);
}

/* Alt view Styling */

.campaigns .alt-view .k-grid td {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 35, 58, 0.1);
  padding: 37px 68px;
}

.campaigns .alt-view .k-grid-table-wrap {
  height: 100%;
}

.campaigns .alt-view .k-progress-status {
  display: none;
}

.altview-campaign-title:hover,
.altview-campaign-title span:hover {
  color: var(--inchcape-blue) !important;
}

.altview-campaign-title {
  color: var(--inchcape-dark-blue) !important;
}

.altview-campaign-title span {
  font-weight: 600;
}

.altview-label-wrap {
  margin-bottom: 12px;
  justify-content: space-between;
}

.altview-label-wrap span {
  font-family: "Poppins";
  font-size: 14px;
  margin-right: 21px;
  color: rgba(0, 35, 58, 0.8);
}

.altview-action-wrap {
  display: flex;
  align-items: center;
  gap: 23px;
}

.alt-view-percent {
  font-family: "Poppins Bold" !important;
  font-size: 14px !important;
  color: var(--inchcape-blue) !important;
}
