.k-window-content:has(.success-modal),
.k-window-content:has(.saving-modal) {
  padding: 40px !important;
  max-width: 313px;
  height: auto;
  text-align: center;
  background: var(--inchcape-white);
  border-radius: 6px;
}

.k-window-content:has(.success-modal) {
  max-width: 290px;
}

.success-modal,
.saving-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.modal-btn {
  display: flex;
  gap: 10px;
}

.success-modal span,
.saving-modal span,
.export-modal {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
}

.saving-modal button {
  width: 50px;
  border: 1px solid var(--inchcape-blue);
  padding: 11px 12.5px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
}

.saving-modal button:focus-visible {
  outline: none;
}

.close {
  cursor: pointer;
  right: 20px;
  top: 20px;
  position: absolute;
}

.export-modal {
  display: flex;
  padding: 23px 25px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.export-modal-title {
  color: var(--inchcape-blue, #16bae7);
  text-align: center;
  font-size: 24px;
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.export-download {
  color: var(--component-text, #00233a);
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.28px;
}

.export-time {
  color: #00233a;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
}

.export-modal button {
  width: 102px;
  border: 1px solid var(--inchcape-blue);
  padding: 11px 12.5px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
}

.export-modal button:focus-visible {
  outline: none;
}
