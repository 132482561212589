.search-module {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.search-module .header {
  padding: 1.25rem;
}

.search-module .d-flex {
  width: 100%;
}

.search {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: end;
  column-gap: 30px;
  row-gap: 10px;
}

.search-module .header button {
  padding: 0 0.7rem;
  font-family: "Poppins";
  font-size: 14px;
}

.search-module .header > button {
  padding: 0 1.2rem;
}

.search-module .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.search-module .offset-1 {
  margin-left: 6%;
}

.search-module .k-sort-icon .k-icon {
  color: var(--inchcape-blue);
}

.search-module .search-btn {
  height: 36px;
  width: 36px;
  align-items: center;
  margin-top: 15px;
}

.search-module .grid-cont {
  width: 100%;
}

.search-module .k-grid {
  height: 60vh;
}

.search-module .k-input:focus,
.k-input:active,
.k-input:hover {
  outline: none;
  box-shadow: none;
}

.search-module tr td:nth-of-type(1) {
  text-align: center;
}

.search-module .custom-pager {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.search-module .custom-pager > .imgs {
  width: max(180px, 14.5vw);
  display: flex;
  justify-content: space-evenly;
}

.search-module .custom-pager .column-hider,
.search-module .k-table-tbody {
  cursor: pointer;
}

.search-module .custom-pager > div:nth-child(2) {
  flex: 1;
}

@media screen and (max-width: 976px) {
  .search {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .search {
    grid-template-columns: repeat(1, 1fr);
  }
}
