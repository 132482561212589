.edit-deal {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.edit-deal-btn {
  display: flex;
  gap: 25px;
}

.edit-deal-btn button {
  font-family: "Poppins Bold";
  font-size: 14px;
  padding: 5px 32px;
}

.edit-deal .swiper {
  width: 100%;
  padding: 25px 20px !important;
  height: 100%;
}

.edit-deal .swiper button {
  height: 20px;
}

.edit-deal .swiper-slide {
  width: 100%;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}

.edit-deal .swiper-slide > * {
  flex: 1;
}

.edit-deal .swiper-pagination-bullets {
  bottom: 0 !important;
  color: transparent;
  background-color: transparent;
}

.edit-deal .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.edit-deal .swiper-pagination-bullet-active {
  background: rgba(0, 35, 58, 0.5) !important;
  opacity: 0.4 !important;
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px rgba(0, 35, 58, 0.5);
}

.edit-deal .slider-btn-next,
.edit-deal .slider-btn-prev {
  position: absolute;
  cursor: pointer;
  top: 38%;
  z-index: 2;
}

.edit-deal .slider-btn-next {
  right: 4.2vw;
  top: 21.5vh;
  z-index: 2;
}

.edit-deal .slider-btn-prev {
  left: 4.2vw;
  top: 21.5vh;
}

.edit-deal .swiper-slide img {
  width: 100%;
}

.edit-deal .swiper-slide span {
  display: inline-block;
  text-align: center;
  border-radius: 6px;
  box-shadow: 6px 5px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid transparent;
}

.edit-deal .swiper-slide p {
  font-family: "Poppins Bold";
  font-size: 13px;
}

.edit-deal .swiper-slide p:first-of-type {
  color: var(--inchcape-dark-blue);
}

.edit-deal .slider-btn-prev.disabled,
.edit-deal .slider-btn-next.disabled {
  filter: brightness(0) saturate(100%) invert(68%) sepia(1%) saturate(7138%)
    hue-rotate(156deg) brightness(88%) contrast(39%);
  user-select: none;
  cursor: initial;
}

.edit-deal .swiper-slide p:nth-of-type(2) {
  color: var(--inchcape-blue);
}

.edit-deal .car-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-deal .car-card span {
  display: flex;
  align-items: center;
}

.edit-deal .car-card span:has(.no-data) {
  padding: 1rem 1rem 1.5rem;
}

.edit-deal .swiper-cont:has(.no-data) {
  padding: 0;
}

.edit-deal .car-card .img-lg {
  opacity: 1;
  width: 244px;
  height: 244px;
}

.edit-deal .car-card .no-data {
  width: 250px;
  height: 208.1px;
}

.edit-deal .car-card:has(.no-data) div {
  color: var(--inchcape-dark-blue);
  width: max(15vw, 250px);
  margin-top: 0.8rem;
}

.edit-deal .car-card:has(.no-data) h4 {
  font-size: 15px;
  font-family: "Poppins Bold";
}

.edit-deal .car-card:has(.no-data) p {
  font-size: 12px;
  font-family: "Poppins";
}

.edit-deal .car-card .img-md {
  width: 182px;
  height: 182px;
}

.edit-deal .car-card:has(.img-md) {
  opacity: 0.75;
}

.edit-deal .car-card .img-sm {
  width: 150px;
  height: 150px;
}

.edit-deal .car-card:has(.img-sm) {
  opacity: 0.25;
}

.edit-deal .edit-deal-btn .secondary-btn {
  user-select: none !important;
  cursor: default;
  pointer-events: none;
}

.edit-deal .edit-deal-btn .primary-btn:active {
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
}

.edit-deal .edit-deal-btn .secondary-btn:hover {
  background-color: var(--inchcape-white);
  color: var(--inchcape-blue);
  box-shadow: none;
}

.edit-deal .k-card-body {
  background: var(--inchcape-white);
  margin-top: 20px;
}

.edit-deal .k-card-body {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.05);
}

.edit-deal .k-card-title {
  width: 137px;
  height: 23px;
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #16bae7;
  margin-bottom: 20px;
  white-space: nowrap;
}

.edit-deal .finance-record {
  height: auto;
  padding-left: 33px;
  padding-right: 33px;
}

.edit-deal .deal-calculator {
  height: auto;
  padding-left: 33px;
  padding-right: 33px;
}

.edit-deal .financeRecordTableGrid {
  border: none !important;
  font-family: "Poppins";
}

.edit-deal .grid-container {
  height: 100%;
  width: 100%;
}

.edit-deal .k-table {
  height: 100%;
}

.edit-deal .tableHeaderCell {
  background-color: #fff;
  color: #00233a99;
  font-weight: 500;
}

.edit-deal .headerTitle {
  margin-bottom: 0px !important;
  font-family: "Poppins Bold" !important;
  font-size: 16px;
}

.edit-deal .proposedHeaderTitle {
  color: #16bae7;
}

.edit-deal .expandedDetails,
.edit-deal .k-grid tr.k-alt,
.edit-deal .k-table-row:has(.expandedDetails),
.edit-deal .k-grid tbody > tr:not(.k-detail-row):hover {
  background-color: rgba(22, 186, 231, 0.04);
}

.edit-deal .monthlyPayment {
  width: 160px;
  font-family: "Poppins Bold" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #16bae7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-deal .monthlyPaymentAmount {
  width: 160px;
  font-family: "Poppins Bold" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-deal .proposedRow {
  width: 160px;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #16bae7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-deal .expandedDetailsRow {
  margin-left: 10px;
}

.edit-deal .grid-row {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #00233ae5;
  position: relative;
}

.edit-deal .caretIconBtn {
  display: flex-end;
  border: none;
  background-color: #ffff;
  float: right;
  width: auto;
  position: absolute;
  right: 0;
}

.edit-deal .calculations,
.type-of-car,
.price,
.term,
.customer-deposit,
.add-dealer-deposit {
  margin-bottom: 35px;
}

.edit-deal .deal-calc-lbl {
  margin-bottom: 10px;
}

.edit-deal .input-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  gap: 10%;
}

.edit-deal .input-container > div {
  min-width: 120px;
  flex: 1;
  margin-bottom: 1rem;
}

.edit-deal .label,
.edit-deal .calculations div {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 35, 58, 0.6);
}

.edit-deal .k-table-td {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 35, 58, 0.6);
  width: 100px;
  white-space: nowrap;
  margin-left: none;
}

.edit-deal .k-table-row,
.edit-deal .k-table {
  background-color: #fff;
  width: 100% !important;
}

.finance-record .k-grid td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.edit-deal .monthly-pay-range {
  margin-bottom: 25px;
}

.edit-deal .campaign {
  margin-bottom: 25px;
}

.edit-deal .finance-type {
  margin-bottom: 25px;
  width: min(65%, 180px);
}

.edit-deal .term-input {
  width: 60%;
}

.edit-deal .deal-calc-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-deal .save-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 98px;
  height: 42px;
  font-weight: 500;
  font-family: "Poppins Bold";
  font-size: 13px;
}
.edit-deal .apply-btn {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 98px;
  height: 42px;
  font-weight: 500;
  font-family: "Poppins Bold";
  font-size: 13px;
}

.edit-deal .k-grid-header {
  background-color: #fff;
}

.edit-deal .k-grid-header-wrap {
  border-right: none;
}

.edit-deal .slider-btn-next,
.edit-deal .slider-btn-prev {
  border: none;
}

.edit-deal .swiper-cont {
  height: 500px;
}

.edit-deal .car-model.selected {
  width: 270px;
  height: auto;
  max-height: 150px;
  font-family: "Poppins Bold";
  font-weight: 500;
  font-size: 22.88px;
  line-height: 29px;
  text-align: center;
  color: #00233a;
  margin-top: 10px;
}

.edit-deal .car-model {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
  width: 80%;
  height: auto;
  font-family: "Poppins Bold";
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  color: #00233a;
  word-break: normal;
  font-size: 17px;
}

.edit-deal .price-styles {
  color: #16bae7;
  font-size: 20px;
}

.edit-deal .orig-price {
  color: #00233a;
  font-size: 14px;
  font-family: "Poppins Bold";
}

.edit-deal .match-pay {
  color: #00233a;
  font-size: 14px;
  font-family: "Poppins Bold";
}

.edit-deal .k-input:focus,
.k-input:active,
.k-input:hover {
  outline: none;
  box-shadow: none;
}

.calculations input {
  width: 100%;
  min-width: 100px;
  max-height: 30px;
}

.calculations label {
  width: min(100%, 160px);
  white-space: nowrap;
}

.calculations div > div {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.edit-deal .finance-record .editable {
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  padding: 0 4%;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.edit-deal .finance-record .editable.read-only {
  border: none;
  padding: 0;
  color: var(--inchcape-blue);
}

.edit-deal .finance-record .editable .k-input {
  padding-left: 0;
  border: none;
  color: inherit;
}

.edit-deal .finance-record .editable span {
  color: rgba(0, 0, 0, 0.16);
  user-select: none;
}

.edit-deal .finance-record .editable img {
  cursor: pointer;
}

.edit-deal .finance-record .editable img.disabled {
  user-select: none;
  pointer-events: none;
  filter: brightness(0) saturate(100%) invert(62%) sepia(18%) saturate(14%)
    hue-rotate(22deg) brightness(89%) contrast(90%);
}

.edit-deal .deal-calculator .k-input-solid.k-invalid {
  border-color: #e5e5e5;
}
.edit-deal .back-btn {
  cursor: pointer;
}

.edit-deal .term-error {
  color: var(--inchcape-red);
  font-size: 12px;
  font-family: "Poppins Bold";
  margin-top: 5px;
}
