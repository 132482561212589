.date-filter-cell .k-dropdownlist::after {
  content: url(../../assets/icons/filterOperator.svg);
  cursor: pointer;
  margin-top: 4px;
  display: inline-block;
  z-index: 2;
  background: #f5f5f5;
  width: 60px;
}

.date-filter-cell .k-dropdownlist {
  border: none;
  margin: 2px;
}

.date-filter-cell .k-dropdownlist .k-input-inner,
.date-filter-cell .k-dropdownlist .k-button {
  display: none;
  border: none;
}

.k-animation-container:has(#date-filter-cell) {
  width: 170px !important;
}
