.settings {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.settings > div {
  gap: 26px;
}

.settings .settings-panel {
  background-color: var(--inchcape-white);
  padding: 23px 33px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}

.settings .transactional-log-panel {
  background-color: var(--inchcape-white);
  width: 100%;
  height: 100%;
  padding: 23px 33px;
  border-radius: 6px;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.05);
}

.settings .transactional-log-panel > h2 {
  color: var(--inchcape-dark-blue);
  margin-bottom: 20px;
}

.settings label {
  color: rgba(0, 35, 58, 0.6);
  font-size: 14px;
}

.settings .input-settings,
.settings .error {
  width: 100%;
}

.settings .settings-panel p {
  font-size: 12px;
  font-family: "Poppins";
  margin-top: 0.4rem;
}

.settings .error label,
.settings .error p {
  color: var(--inchcape-red);
}

.settings .error .k-input-flat {
  border-bottom: 1px solid var(--inchcape-red);
}

.settings-panel button {
  padding: 11px 32px;
  font-family: "Poppins Bold";
  font-size: 13px;
}

/* FOR SETTINGS CONFIRMATION MODAL */
.k-window-content:has(.settings-modal-confirmation) {
  width: 340px;
  padding: 25px 23px;
}

.settings-modal-confirmation p {
  font-family: "Poppins Bold";
  font-size: 14px;
  color: var(--inchcape-dark-blue);
  margin-top: 14px;
}

.settings-modal-confirmation span {
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-dark-blue);
}

.button-group button {
  font-size: 13px;
  font-family: "Poppins";
  padding: 11px 32px;
  margin: 10px 0;
}

.settings .transactional-log-panel .grid {
  padding: 28px 28px 12px 28px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.05);
}

.settings .transactional-log-panel .grid .k-grid {
  height: 530px;
}

.settings .transactional-log-panel label {
  color: var(--inchcape-dark-blue);
}

.settings .filters .k-input-button {
  border-inline-start-width: 0;
}

.settings .filters .k-button-solid-base {
  background-color: var(--inchcape-white);
}

.settings .grid .k-grid-header {
  display: none;
}

.filters .daterange-picker .k-daterangepicker {
  gap: 2rem !important;
}

.filters .k-floating-label-container {
  width: 100%;
}

.filters .daterange-picker > label {
  width: 50%;
}

.filters .daterange-picker .k-dateinput {
  width: 100%;
}

.daterange-picker label {
  width: 100%;
}

.daterange-picker .date-label {
  display: block;
  margin-bottom: 5px;
}

.filters .k-picker-solid:hover {
  background: var(--inchcape-white);
}

.filters .k-multiselect::after {
  content: "\25bc";
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: var(--inchcape-dark-blue);
  z-index: 2;
  top: 30%;
  right: 3%;
  cursor: pointer;
}

.filters .k-multiselect {
  caret-color: transparent;
}

.filters .k-input-md .k-input-values > .k-input-inner {
  cursor: pointer;
}

/* FILTER STYLING */
.filters .k-multiselect .k-input-values {
  flex-wrap: nowrap;
  height: 30px;
  max-width: 309px;
}

.filters .k-multiselect .k-input-inner {
  caret-color: transparent;
  width: 100%;
  position: absolute;
  background-color: transparent;
}

.filters > div:nth-child(2) .k-clear-value,
.filters .k-chip-actions {
  display: none;
}

.filters .k-chip:hover {
  background: var(--inchcape-white);
}

.filters .k-chip-list {
  width: 93%;
}

.filters .k-chip {
  width: 100%;
}
