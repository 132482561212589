.access-control {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.access-control .back-btn {
  cursor: pointer;
}

.access-control .access-control-table {
  height: 95%;
  background-color: #fff;
  border-radius: 4px;
  min-width: 1200px;
}

.access-control label {
  font-size: 14px;
}

.access-control .table-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.access-control .k-grid {
  height: 85%;
}

.access-control .module-icon {
  margin-right: 20px !important;
}

.access-control .actions-module {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.access-control .actions-module-checkbox {
  display: flex;
  flex-direction: column;
}

.access-control .actions-module .action,
.access-control .actions-module-checkbox .action {
  padding: 10px;
}

.access-control .save-btn {
  width: 98px;
  height: 42px;
}

.access-control .control-btn {
  float: right;
  margin-right: 80px;
}
