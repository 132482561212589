.portfolio {
  width: 100%;
  min-width: 560px;
  grid-area: 1 / 1 / -1 / -1;
}

.swiper-cont {
  background: var(--inchcape-white);
  width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 0 4rem 0 4.5rem;
  height: 270px;
}

.portfolio .swiper {
  width: 100%;
  padding: 20px !important;
  height: 100%;
}

.portfolio .swiper button {
  height: 20px;
}

.portfolio .swiper-slide {
  width: 100%;
  display: flex !important;
  justify-content: space-evenly;
}

.portfolio .swiper-pagination-bullets {
  bottom: 12px !important;
}

.portfolio .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.portfolio .swiper-pagination-bullet-active {
  background: rgba(0, 35, 58, 0.5) !important;
  opacity: 0.4 !important;
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px rgba(0, 35, 58, 0.5);
}

.portfolio .slider-btn-next,
.portfolio .slider-btn-prev {
  position: absolute;
  cursor: pointer;
  top: 38%;
  z-index: 2;
}

.portfolio .slider-btn-next {
  right: 4.5vw;
  z-index: 2;
}

.portfolio .slider-btn-prev {
  left: 4vw;
}

.portfolio .swiper-slide img {
  width: 100%;
}

.portfolio .car-card {
  padding: 15px 0;
}

.portfolio .car-card div {
  width: auto;
  height: 100%;
  align-items: center;
}

.portfolio .swiper-slide span.selected {
  border: 2px solid var(--inchcape-blue);
  box-shadow: 6px 5px 10px rgba(22, 186, 231, 0.25);
}

.portfolio .swiper-slide span {
  display: inline-block;
  text-align: center;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  box-shadow: 6px 5px 18px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid transparent;
}

.portfolio .swiper-slide p {
  font-family: "Poppins Bold";
  font-size: 13px;
}

.portfolio .swiper-slide p:first-of-type {
  color: var(--inchcape-dark-blue);
}

.portfolio .slider-btn-prev.disabled,
.portfolio .slider-btn-next.disabled {
  filter: brightness(0) saturate(100%) invert(68%) sepia(1%) saturate(7138%)
    hue-rotate(156deg) brightness(88%) contrast(39%);
  user-select: none;
  cursor: initial;
}

.portfolio .swiper-slide p:nth-of-type(2) {
  color: var(--inchcape-blue);
}

.portfolio .no-result {
  font-family: "Poppins";
  font-weight: 600;
  color: rgba(0, 35, 58, 0.9);
  margin-bottom: 0px;
}

.portfolio .no-result-data {
  margin-bottom: 5px;
  font-family: "Poppins";
}

.portfolio .ellipse-img {
  margin-top: 10px;
  opacity: 60%;
}

.portfolio .no-result-img {
  height: 150px;
}

.portfolio .k-grid {
  height: 450px;
}

.portfolio .module-cont .header {
  padding: 1.2rem;
}

.portfolio .primary-btn {
  font-family: "Poppins";
  font-size: 13px;
}

.portfolio .primary-btn:first-of-type {
  padding: 0.7rem 2rem;
}

.portfolio .primary-btn:nth-of-type(2) {
  padding: 0.5rem 0.6rem;
}

.portfolio .k-checkbox-label {
  display: block !important;
}

.portfolio tr td:first-of-type,
.portfolio tr td:nth-of-type(2) {
  text-align: center;
}

.portfolio tr:first-child th:first-child > div {
  cursor: pointer;
}

.portfolio .custom-pager {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.portfolio .k-pager {
  flex-wrap: wrap;
  flex: 0.85;
}

.portfolio .custom-pager img {
  cursor: pointer;
}

.portfolio .k-table,
.portfolio .k-grid-table,
.portfolio .k-table-md {
  width: 100% !important;
}

.portfolio .k-table tr {
  cursor: pointer;
}

.portfolio .k-table tr.k-disabled {
  pointer-events: initial;
  cursor: default;
}

.portfolio .k-dropdown-operator.k-picker-md .k-input-button {
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: #f5f5f5;
}

.k-list-item:has(.bulk-status) {
  justify-content: center;
  padding: 8px 16px;
}

.last-spacing {
  display: flex;
  align-items: center;
}

.last-spacing > * {
  margin-right: 45px;
}

.last-spacing > p {
  flex-grow: 1;
  min-width: 0;
}
