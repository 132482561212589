.main {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.left,
.right {
  flex: 1;
}

.left {
  background: #fff;
  padding: 1.5rem 2.5rem;
  color: var(--inchcape-dark-blue);
}

.left > div {
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 4.5vh;
  align-items: flex-start;
}

.contents a {
  color: var(--inchcape-dark-blue);
  font-size: min(6vw, 20px);
  font-family: "Poppins";
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}

.contents button {
  padding: 0.8rem 2rem;
  box-shadow: 0px 4px 7px rgba(16, 156, 241, 0.24);
  font-family: "Poppins";
}

.left img {
  width: min(40vw, 180px);
}

.right {
  background-image: linear-gradient(
      90.07deg,
      rgba(0, 0, 0, 0.75) 6.25%,
      rgba(13, 13, 13, 0.8) 52.6%,
      rgba(65, 65, 65, 0.5276) 72.4%,
      rgba(137, 137, 137, 0) 88.54%,
      rgba(217, 217, 217, 0) 99.94%
    ),
    url("../../imagenes/loginbackground/loginbackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% top;
}

.right div {
  margin-top: 15vh;
}

.right p {
  font-size: min(7.6vw, 100px);
  line-height: 0.75;
  font-weight: bold;
  font-family: "Poppins";
  color: #fff;
}

.right img {
  height: 12vh;
  margin-right: 7vw;
}

.title,
.msg {
  margin: 0;
}

.title {
  font-size: min(8.5vw, 30px);
  font-family: "Poppins Bold";
}

.msg {
  font-size: min(6vw, 20px);
  font-family: "Poppins";
}

@media screen and (max-width: 575.9px) {
  .main {
    min-height: 100vh;
    overflow-y: auto;
  }

  .right {
    max-height: 400px;
    flex: 0;
  }

  .right div {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .right img {
    height: 8vh;
    margin-right: 0;
  }

  .right p {
    font-size: 13vw;
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  box-shadow: none;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.input {
  padding: 8px;
  font-size: 14px;
  font-family: "Poppins";
  border: none;
  border-bottom: 1px solid #ccc;
  width: 400px;
  outline: none;
}

.qrCodeContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0 110px;
  align-items: center;
  height: 200px;
}
.qrCode {
  max-width: 100%;
  height: auto;
}

.error {
  font-family: "Poppins";
  color: red;
  margin: 0;
  font-weight: bold;
}



