.main {
  height: 100vh;
  overflow: hidden;
  background: white;
  background-image: linear-gradient(
      90.07deg,
      rgba(0, 0, 0, 0.75) 6.31%,
      rgba(13, 13, 13, 0.677991) 52.6%,
      rgba(65, 65, 65, 0.395718) 72.37%,
      rgba(137, 137, 137, 0) 88.5%,
      rgba(217, 217, 217, 0) 99.94%
    ),
    url("../../imagenes/loginbackground/loginbackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 min(10vw, 12rem);
}

.lines {
  height: 12vh;
}

.contents {
  display: flex;
  flex-direction: column;
  height: 62%;
}

.contents p {
  font-size: min(14vw, 130px);
  line-height: 0.9;
  font-family: "Poppins Bold";
  color: #fff;
}

.contents img {
  width: min(42vw, 215px);
}

.contents button {
  align-self: flex-start;
  padding: min(4vw, 0.8rem) min(8vw, 2.2rem);
  font-size: min(3.5vw, 15px);
  font-weight: bold;
  font-family: "Poppins Light";
  letter-spacing: 1px;
}


.dropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
}

.dropdownLabel {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.customDropdown {
  width: 25%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  background: transparent !important;
  cursor: pointer;
  appearance: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 6px;
}

@media screen and (max-width: 575px) {
  .main {
    background-image: linear-gradient(
        90.07deg,
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0)
      ),
      url("../../imagenes/loginbackground/loginbackground.jpg");
    background-position: 27% top;
  }

  .contents p {
    line-height: 0.7;
  }
}
