.user-form {
  grid-area: 1 / 1 / -1 / -1;
}

.user-form .header img {
  cursor: pointer;
}

.user-form .module-cont {
  padding: 1.5rem 1rem;
  margin: 0;
}

.user-form label:not(.branch)::after {
  content: "*";
}

.user-form label {
  color: rgba(0, 35, 58, 0.6);
  font-size: 14px;
}

.user-form .primary-btn {
  padding: 0.65rem 0;
  font-family: "Poppins";
  width: max(7.5%, 102px);
  font-size: 13px;
}

.user-form .k-clear-value {
  display: none;
}

.user-form .k-disabled .k-chip-actions {
  display: none;
}
