.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: white;
  margin: 0;
}

.left {
  flex: 3.5;
}

.right {
  flex: 1;
}

.left {
  background-image: linear-gradient(
      90.07deg,
      rgba(0, 0, 0, 0.75) 6.31%,
      rgba(13, 13, 13, 0.677991) 52.6%,
      rgba(65, 65, 65, 0.395718) 72.37%,
      rgba(137, 137, 137, 0) 88.5%,
      rgba(217, 217, 217, 0) 99.94%
    ),
    url("../../imagenes/loginbackground/loginbackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.left p {
  font-size: min(7.6vw, 100px);
  line-height: 0.75;
  font-weight: bold;
  font-family: "Poppins";
  color: #fff;
}

.right {
  background: #fff;
  padding: 3.5rem 4.5rem;
  color: var(--inchcape-dark-blue);
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 min(10vw, 12rem);
}

.lines {
  height: 12vh;
}

.contents {
  display: flex;
  height: 62%;
  justify-content: space-between;
  gap: 120px;
}

.contents p {
  font-size: min(14vw, 130px);
  line-height: 0.9;
  font-family: "Poppins Bold";
  color: #fff;
}

.contents img {
  width: min(42vw, 215px);
}

.contents button {
  align-self: flex-start;
  padding: min(4vw, 0.8rem) min(8vw, 2.2rem);
  font-size: min(3.5vw, 15px);
  font-weight: bold;
  font-family: "Poppins Light";
  letter-spacing: 1px;
}

.select-country-wrap .select-country-title {
  width: 100%;
  display: block;
  font-family: "Poppins Bold";
  font-size: min(14vw, 32px);
  text-align: center;
  color: var(--inchcape-dark-blue);
  text-align: center;
  margin-bottom: 45px;
  border-bottom: 1px solid #e7e5e5;
  padding: 28px 0 44px 0;
  text-transform: uppercase;
}

.region {
  display: inline-block;
  font-family: "Poppins Bold";
  font-size: 22px;
  margin-bottom: 26px;
}

.markets {
  margin-bottom: 26px;
}

.select-country-wrap button {
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: transparent;
  border-radius: 4px;
  outline: none;
  border: none;
  margin-bottom: 18px;
  padding: 11px 32px;
}

.select-country-wrap .markets button img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.select-country-wrap button span {
  color: var(--inchcape-dark-blue);
  font-family: "Poppins";
  font-size: 18px;
}

.select-country-wrap button:disabled,
.select-country-wrap button span:disabled {
  pointer-events: none;
  color: var(--inchcape-light-grey);
  opacity: 0.6;
}

.select-country-wrap button span:hover {
  color: var(--inchcape-blue);
}

@media screen and (max-width: 575px) {
  .main {
    background-image: linear-gradient(
        90.07deg,
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0)
      ),
      url("../../imagenes/loginbackground/loginbackground.jpg");
    background-position: 27% top;
  }

  .contents p {
    line-height: 0.7;
  }

  .main {
    min-height: 100vh;
    overflow-y: auto;
  }

  .right {
    max-height: 100vh;
    flex: 0;
  }

  .right div {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .right img {
    height: 8vh;
    margin-right: 0;
  }

  .right p {
    font-size: 13vw;
  }
}
