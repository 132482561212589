.desking-sheet {
  position: absolute;
  top: -5000px;
}

.desking-sheet p {
  margin: 0;
}

.desking-sheet .k-grid-table-wrap {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}

.desking-sheet .pdf-title {
  color: var(--inchcape-dark-blue);
  font-size: 15px;
  margin-bottom: 10px;
}

.desking-sheet .pdf-header-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  row-gap: 54px;
  column-gap: 10px;
  margin-bottom: 8px;
}

.desking-sheet .pdf-header-container-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1px;
}

.desking-sheet .pdf-header-container-label,
.pdf-title-common {
  font-size: 8px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .pdf-header-container-value {
  font-size: 8px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .k-column-title {
  font-size: 7px;
}

.desking-sheet .k-grid-content {
  overflow: hidden;
}

.desking-sheet .k-cell-inner > .k-link {
  padding: 2px 12px;
}

.desking-sheet .row-item {
  font-size: 7px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .rowCell,
.desking-sheet .pdf-grid-title-detail-footer,
.desking-sheet .pdf-grid-title-existing-footer,
.desking-sheet .pdf-grid-title-detail-footer {
  padding: 2px 8px;
}

.desking-sheet .proposed-row-item {
  color: var(--inchcape-blue);
  font-size: 7px;
}

.desking-sheet .expanded-details-row {
  color: var(--inchcape-dark-blue);
  font-size: 7px;
}

.desking-sheet .monthly-payment-amount {
  font-size: 7px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .row-highlight-label,
.desking-sheet .row-highlight {
  font-size: 7px;
  color: var(--inchcape-blue);
}

.desking-sheet .header-row-highlight-label {
  font-size: 7px;
  color: var(--inchcape-blue);
  padding: 0;
}

.desking-sheet .header-row-highlight-label-existing {
  font-size: 7px;
  padding: 0;
}

.desking-sheet .header-row-highlight-label-details {
  font-size: 7px;
  padding: 0;
}

.desking-sheet .pdf-grid-two-col {
  display: flex;
  gap: 10px;
}

.desking-sheet .pdf-grid-vehicle-details,
.desking-sheet .pdf-grid-finance-details,
.desking-sheet .pdf-grid-two-col {
  margin-bottom: 8px;
}

.desking-sheet .footer-grid {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 14.8px);
  margin-bottom: 14px;
}

.desking-sheet .footer-grid div {
  margin: 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}

.desking-sheet .pdf-grid-title-footer {
  font-size: 7px;
  color: rgba(0, 35, 58, 0.6);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}

/* PDF Translations for Traditional Chinese */
.hk-font-bold {
  font-family: "Noto Sans TC Bold";
}

.hk-font {
  font-family: "Noto Sans TC";
}

.en-font-bold {
  font-family: "Poppins Bold";
}

.en-font {
  font-family: "Poppins";
}

.bg-font {
  font-family: "Noto Sans";
}

.bg-font-bold {
  font-family: "Noto Sans Bold";
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../assets/fonts/NotoSans-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Light";
  src: url("../../assets/fonts/NotoSans-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Bold";
  src: url("../../assets/fonts/NotoSans-SemiBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../assets/fonts/NotoSansTC-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans TC Light";
  src: url("../../assets/fonts/NotoSansTC-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans TC Bold";
  src: url("../../assets/fonts/NotoSansTC-SemiBold.ttf");
  font-style: normal;
}