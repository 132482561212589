.reports {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.reports-btn {
  display: flex;
  align-items: center;
  padding: 20px 78px;
  gap: 30px;
  width: 400px;
  height: 90px;
  border: none;
  background-color: var(--inchcape-white);
  color: var(--inchcape-grey);
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
}

.reports-btn:hover {
  background-color: #fafafa;
  color: var(--inchcape-grey);
  transition: all 0.3s ease;
}

.reports-btn:focus-within {
  outline: none;
}

.reports-btn span {
  font-family: "Poppins Bold";
  font-size: 18px;
  line-height: 23px;
  text-align: start;
  letter-spacing: 0.01em;
  white-space: normal;
}
