.campaign-leads {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.campaign-leads .header {
  padding: 1.5rem;
}

.campaign-leads .select-all {
  margin-left: 5px;
}

.campaign-leads img {
  cursor: pointer;
}

.campaign-leads .edit-campaign {
  font-family: "Poppins Bold";
  font-size: 13px;
  padding: 11px 32px;
  letter-spacing: 0.13px;
}

.campaign-leads .header.grid-view,
.campaign-leads .header.grid-view input {
  background-color: #fafafa;
}

.campaign-leads .k-grid {
  height: 60vh;
}

.campaign-leads .grid-view .k-grid {
  border: 1px solid #fafafa;
}

.campaign-leads .grid-cont {
  overflow: hidden;
  overflow-x: auto;
}

.campaign-leads .search img {
  width: 14px;
  height: 14px;
}

.campaign-leads .header .search button {
  padding: 11px;
}

.campaign-leads .header button {
  padding: 11px 32px;
  font-family: "Poppins Bold";
  font-size: 13px;
}

.campaign-leads .header label {
  font-size: 14px;
  color: rgba(0, 35, 58, 0.6);
}

.campaign-leads .offset-1 {
  margin-left: 2%;
}

.campaign-leads .custom-pager {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.campaign-leads .custom-pager > .imgs {
  flex: 0.25;
  display: flex;
  justify-content: space-evenly;
}

.campaign-leads .custom-pager > div:nth-child(2) {
  flex: 0.7;
}

.campaign-leads .k-table-row .k-button {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.campaign-leads .k-sort-icon .k-icon {
  color: var(--inchcape-blue);
}

.campaign-leads th:first-of-type .k-cell-inner {
  justify-content: center;
}

.campaign-leads td:first-of-type img,
.campaign-leads .imgs img {
  cursor: pointer;
}

.campaign-leads .imgs img {
  margin: 10px;
  border-radius: 4px;
}

.campaign-leads .k-pager-info {
  display: block;
}

.campaign-leads .k-grid-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.campaign-leads span.selected {
  background: var(--inchcape-blue);
  border-radius: 4px;
}

.campaign-leads img.selected {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%)
    hue-rotate(327deg) brightness(106%) contrast(101%);
}

.campaign-leads .k-table {
  width: 100% !important;
}

.campaign-leads tr td:nth-child(3) {
  text-align: center;
}

.campaign-leads .k-table-row {
  cursor: pointer;
}

/* Lead Bulk Action Styling */
.campaign-bulk-action .k-dialog-content {
  width: min(60vw, 310px);
}

.campaign-bulk-action img {
  cursor: pointer;
}

.campaign-bulk-action label {
  color: rgba(0, 35, 58, 0.6);
}

.campaign-bulk-action .btn-group button {
  flex: 1;
  font-family: "Poppins";
  padding: 0.8rem;
}

.campaign-bulk-action .inputs {
  gap: 3rem;
}

.campaign-bulk-action .custom.k-input:focus,
.campaign-bulk-action .custom.k-input-md {
  outline: none;
  box-shadow: none;
}
