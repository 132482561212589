.service-alerts-container {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
}

.service-alerts-box {
  width: 493px;
  background-color: var(--inchcape-white);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.service-alerts-components {
  width: 252px;
  gap: 15px;
}

.service-alerts-components p {
  font-family: "Poppins Bold";
  color: rgba(0, 35, 58, 0.6);
  margin: 0;
}

.service-alerts-contents .primary-btn,
.service-alerts-grid .primary-btn {
  display: flex;
  align-items: center;
  padding: 11px 32px;
  gap: 10px;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  text-align: center;
}

.service-alerts-grid .primary-btn:disabled,
.service-alerts-contents .primary-btn:disabled {
  box-shadow: 0px 4px 10px rgba(143, 143, 143, 0.24);
}

.service-alerts-contents .primary-btn img {
  width: 14px;
  height: 14px;
}

.service-alerts-contents .primary-btn span,
.service-alerts-grid .primary-btn span {
  font-family: "Poppins";
  font-size: 14px;
}

/* Grid Styling */
.service-alerts-grid .k-grid {
  height: 45vh;
}

.service-alerts-grid {
  overflow: hidden;
  overflow-x: auto;
}

.service-alerts-grid .k-selectable {
  cursor: pointer;
}

.service-alerts-grid .k-disabled {
  pointer-events: all;
}

.service-alerts-grid .k-table-row:hover,
.service-alerts-grid .k-grid tr.k-alt {
  background: #fff !important;
}

.service-alerts-grid .k-grid td .equity-cell {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
}

.service-alerts-grid .custom-pager {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.service-alerts-grid .k-pager {
  flex-wrap: wrap;
  flex: 0.85 1;
}

.service-alerts-grid .custom-pager img {
  cursor: pointer;
}

.service-alerts-grid tr:first-of-type th:first-of-type label {
  color: rgba(0, 35, 58, 0.6);
}

.service-alerts-grid tr td:first-of-type {
  text-align: center;
}

.service-alerts-grid .k-checkbox-label {
  display: block;
}

/* Service Alerts Modal */
.service-alerts-modal {
  width: 313px;
  height: 225px;
  background: var(--inchcape-white);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 30px;
}

.service-alerts-modal span {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
}
