.preview-leads .k-dialog-content {
  width: min(90vw, 964px);
}

.preview-leads .modal-cont {
  height: 100% !important;
}

.preview-leads .select-all {
  margin-left: 5px;
}

.preview-leads .grid-cont {
  overflow: auto;
  height: 100% !important;
}

.preview-leads .grid-view {
  height: 52vh;
}

.preview-leads .buttons {
  bottom: 15px;
}

.preview-leads .cancel-btn {
  width: 112px;
  height: 42px;
}

.preview-leads .leads-input {
  width: 60px;
}

.preview-leads .equity {
  color: #b7cd00;
}

.preview-leads .negative-equity {
  color: #e40521;
}

.preview-leads .loading.full {
  min-height: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media screen {
  .preview-leads .k-dialog-content {
    width: min(90vw, 964px);
  }
}
