.vehicle-selector .k-dialog-content {
  width: min(80vw, 964px);
  max-height: 90vh;
  border-radius: 4px;
  padding: 0;
}

.vehicle-selector .k-dialog-content,
.vehicle-selector .k-input-inner,
.vehicle-selector .k-button-md,
.vehicle-selector .k-list,
.vehicle-selector .filter-btn {
  background: #fafafa;
}

.vehicle-selector .h1 {
  font-size: 18px;
}

.vehicle-selector .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
}

.vehicle-selector .filters > * {
  flex: 1;
  width: 20%;
}

.vehicle-selector .secondary-btn {
  margin-top: 0.8rem;
}

.vehicle-selector .cancel-btn {
  border: none;
}

.vehicle-selector .secondary-btn,
.vehicle-selector .bg-none-btn,
.vehicle-selector .primary-btn {
  font-family: "Poppins Bold";
  padding: 11px 32px;
  border-radius: 4px;
  font-size: 13px;
}

.vehicle-selector .k-input-inner,
.vehicle-selector .k-input-value-text {
  font-family: "Poppins Light";
  color: var(--inchcape-dark-blue);
}

.vehicle-selector label:not(.k-grid label) {
  font-family: "Poppins Bold";
  color: rgba(0, 35, 58, 0.6);
}

.vehicle-selector .filters > div:last-child {
  margin-right: 3vw;
}

.vehicle-selector .k-grid {
  border-color: #fafafa;
  height: 450px;
}

.vehicle-selector tr:first-child th:first-child > div {
  cursor: pointer;
}

.vehicle-selector td:first-of-type {
  text-align: center;
}

.vehicle-selector .k-grid-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.vehicle-selector .k-dropdown-operator.k-picker-md .k-input-button {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.vehicle-selector .custom-pager {
  padding: 0.3rem 1rem 0.8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.vehicle-selector .k-checkbox {
  border: 2px solid #dadee3;
  background-color: transparent;
}

.vehicle-selector td {
  cursor: pointer;
}

@media screen and (max-width: 768.9px) {
  .vehicle-selector .k-dialog-content {
    width: 85vw;
  }

  .vehicle-selector .filters {
    flex-direction: column;
    gap: 3vh;
  }

  .vehicle-selector .filters > div:last-child {
    margin-right: 0;
  }

  .vehicle-selector .custom-pager {
    flex-direction: column;
  }

  .vehicle-selector .filters > * {
    width: 100%;
  }
}
